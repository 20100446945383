.header-wrapper {
  padding-top: 0em;
  padding-bottom: 0em;
}

.logo {
  margin-right: auto;
  font-size: 3.2em;
  font-weight: 700;
  width: 50px;
  height: 50px;
  transition: ease-in-out 0.3s;
  color: var(--white);
}

.faChevronRight, .faCartShopping, .faUser {
  font-size: 1.5em;
}
.faChevronRight:hover, .faCartShopping:hover, .faUser:hover {
  color: rgb(55, 55, 55);
}

.banner-wrapper {
  background-color: var(--footer);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 20%;
  position: relative;
  z-index: 2;
  padding: 7.5em 0;
}

.bg-header-wrapper, 
.video-demo {
  background-color: var(--footer);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 20%;
  position: relative;
  z-index: 1;
  padding: 7.5em 0;
}

.banner-wrapper {
  background-image: url('/src/assets/images/bg-header-1.jpg');
  padding: var(--size-100) 0;
}

.bg-header-wrapper {
  background-image: url('https://media.istockphoto.com/id/1365105724/photo/multicultural-businesspeople-working-in-an-office-lobby.jpg?s=2048x2048&w=is&k=20&c=iu3fo6GEdNhwibHvD9xxsmSTQm3V9rjAMk9LbeJmlyY=');
  padding: var(--size-200) 0;
}

.banner-wrapper::before,.bg-header-wrapper::before {
  background-image: linear-gradient(
    30deg,
    rgba(21, 27, 38, 0.8) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
}



.hero-header {
  color: var(--white);
  font-size: var(--size-30);
}

.hero-paragraph {
  color: #ffffffc1;
  font-size: var(--size-16);
}

.iconcenter {
  align-items: center;
}

.card{
 margin-bottom: 5em;
}

.contact-img {
  padding-top: 20em;
  object-fit: fill;
}

.ptext {
  font-size: 1.8em;
  color: #000;
  font-weight: 600;
  padding-top: 3em;
}

.ready-made-courses {
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 33px;
  padding-top: 0;
}

.ready-made-courses__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ready-made-courses__title {
  width: 100%;
}

.title_h2 {
  font: 38px/1.1 Euclid Circular B SemiBold,Roboto,sans-serif;
}

.title_h1, .title_h2 {
  color: #151824;
  margin: 0;
}

.ready-made-courses__text {
  margin: 20px 0 14px 2px;
  width: 100%;
}

.text {
  color: var(--footer-color-base);
  font: 18px/1.3 Euclid Circular B Regular,Roboto,sans-serif;
}

.ready-made-courses__button {
  display: block;
  margin-top: 15px;
  max-width: 205px;
}

.button_white {
  background: transparent;
  border: 1px solid #151824;
  color: #151824;
  padding: 19px;
}

.button {
  border-radius: 200px;
  display: inline-block;
  font: 16px/1 Euclid Circular B,Roboto,sans-serif;
  padding: 20px;
  transition: all .3s ease;
}

.button, .text_center {
  text-align: center;
}

.ready-made-courses__img-wrapper {
  position: relative;
}

/** Hire Us Section*/
.hire-us {
  background-color: #263558;
  padding: 0 2rem;
}

.hire-us-sec {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 1.5rem;
}

.hire-us-block {
  position: relative;
  padding: 2rem;
  left: 16%;
  width: 450px;
}

.hire-us-hdr {
  color: white;
  margin: 0 0 16px 0;
  font-size: var(--size-30);
}

.hire-us-subtxt {
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0;
  color: #abb0be;
  font-size: var(--size-14);
}

.hire-us-hr {
  width: 28%;
  margin: 6px 0 6px 0;
  border: 1px solid #4c5975;
}

.hire-us-txt {
  width: 75%;
  line-height: 20px;
  color: white;
}

.charlotte {
  margin: 18px 0 0 0;
  font-size: var(--size-15);
  color: white;
}

.slack {
  margin: 6px 0 0 0;
  font-size: var(--size-12);
  color: #abb0be;
}

.hire-us-txt2, .james, .hubspot2 { display: none; }

.people {
  display: flex;
  align-items: center;
}

.two-people {
  border-radius: 50%;
  width: 5%;
  margin: 38px 20px 15px 5px;
  filter: grayscale(55%);
}

.one-person {
  border-radius: 50%;
  width: 7%;
  margin: 25px 0 0 0;
}

.form {
  background-color: white;
  margin: 1rem;
  padding: 16px 30px 30px 30px;
  border-radius: 5px;
  width: 44%;
}

.fill {
  display: flex;
  margin: 10px 4px 2px 0;
  color: var(--font-color-dark);
  font-size: var(--size-16);
  line-height: var(--size-20);
}

.fill-out {
  display: flex;
  margin-top: var(--size-20);
}

.fname,
.lname {
  margin: 0 8px 0 0;
  color: #aab3bb;
  border: 2px solid #ebedf3;
  border-radius: 5px;
  width: 44%;
  padding: 4%;
  font-size: var(--size-12);
}

.email {
  margin: 0 8px 0 0;
  color: #aab3bb;
  border: 2px solid #ebedf3;
  border-radius: 5px;
  width: 44%;
  padding: 4%;
  font-size: var(--size-12);
}

.budget {
  margin: 0 8px 0 0;
  border: 2px solid #ebedf3;
  border-radius: 5px;
  width: 50%;
  padding: 8px 8px 8px 8px;
  font-size: var(--size-12);
}

.text-area {
  display: flex;
  margin-top: var(--size-20);
  padding: 10px 10px 0 10px;
  width: 90%;
  font-size: 11px;
  border: 2px solid #ebedf3;
  border-radius: 5px;
  color: #aab3bb;
}

.marketing {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 10px 0 0 0;
  top: 6%;
  width: 100%;
}

.checkbox {
  position: relative;
  margin: 2px 0 0 0;
  left: -4%;
  zoom: 1.5;
}

.emails {
  margin: 0 -4px 0 0;
  font-size: var(--size-10);
  line-height: var(--size-18);
}

.policy { border-bottom: 1px dotted #4f84f4; }

.submit-btn {
  display: flex;
  justify-content: center;
  position: relative;
  top: 8%;
}

.submit {
  color: white;
  background-color: #4f84f4;
  border: none;
  border-radius: var(--size-18);
  padding: var(--size-12);
  font-size: var(--size-16);
  width: 35%;
  margin: 24px 0 0 0;
  transition: 0.5s;
  cursor: pointer;
  box-shadow: rgb(149 157 165 / 30%) 0px 8px 24px;
}

.submit:hover { transform: scale(1.1); }
/* FilterBar css */
.filter-bar {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px;
  background-color: #f5f5f5; /* Adjust the background color as needed */
}

.filter-button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.filter-dropdown {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  background-color: #ffffff;
  -webkit-appearance: none; /* Removes default styling on select elements for Webkit browsers */
  -moz-appearance: none;    /* Removes default styling on select elements for Firefox */
  appearance: none;
}

/* You might need to include custom arrow icons for the select elements */
.select::after {
  content: "\25BC";
  float: right;
}

.card {
  flex-wrap: nowrap;
}
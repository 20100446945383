
.faArrowDown {
  margin-left: 1em;
  font-weight: 600;
}

.line {
  width: 100%; 
  height: 0.1em; 
  background-color: var(--font-color-light); 
}

.learning-materials-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.outer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; 
}

.centered-wrapper {
  text-align: center;
}

.learning-materials-wrapper {
  
}

.learning-text-wrapper {
  margin-top: 1em;
  font-size: var(--size-20);
  line-height: 3em;
}

.faBook {
  font-size: var(--size-100);
}

.text-decoration-underline {
  text-decoration: underline;
}

.supported-formats {
  margin-top: 3em;
  font-size: var(--size-14);
}



.new-project-container {
  align-items: flex-start;
  display: flex;
  margin-block-end: var(--size-16);
 }

 .new-project-text-wrapper {
  display: flex;
  flex-grow: 1;
  min-width: 0;
  padding-inline-end: var(--size-40);
 }

 .text-row {
  align-items: center;
  display: flex;
  flex-grow: 1;
  min-width: 0;
 }

 .text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 }

 .text-wrapper::before {
  content: "";
  display: block;
 }
  
 .text-wrapper::after {
  content: "";
  display: block;
 }

 .new-project-text {
  color: var(--font-color-dark);
  font-size: var(--size-14);
  font-weight: 600;
 }

 .icons-wrapper {
  align-items: center;
  display: flex;
  margin-block-start: 8px;
 }

 .icon-list-container {
  align-items: center;
  display: inline-flex;
  flex-direction: row-reverse;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
 }

 .icon-list-item:first-child {
  margin-inline-end: 0;
 }

 .icon-container {
  display: flex;
  height: fit-content;
 }

 .icon-list-item .icon-list-item-initials>div, .icon-list-item .icon-list-item-counter, .icon-list-item:last-child .icon-list-item-initials>div {
  box-shadow: 0 0 0 0.2em var(--white);
}

.icon-initials-style-default.icon-initals-confirmed {
  background: var(--color-avatar-grey);
}

.icon-initials-style-default.icon-initials-confirmed {
  background: var(--font-color-light);
  padding: 1em;
  border-radius: 50%;
}

.icon-initials-style-default.icon-initials-confirmed .icon-initials > h3 {
  color: var(--white);
  font-size: var(--size-12);
  font-weight: 600;
}

.add-collaborators {
  align-items: center;
  background: var(--white);
  border-radius: 50%;
  color: var(--font-color-light);
  cursor: pointer;
  display: flex;
  height: 2.8em;
  justify-content: center;
  margin-inline-start: 0.4em;
  width: 2.8em;
}

.manage-project {
  color: var(--font-color-light);
  margin-left: 0.1em;
  padding: 0;
  font-size: var(--size-40);
  display: flex;
  align-items: center;
}

.button-wrapper {
  display: flex;
}

.icon-button.create-folder-button {
  padding-block: 0;
  padding-inline: 0.9em;
}

.create-folder-icon {
  background-color: var(--white);
}

.btn-container {
  border-radius: 0.4em;
  display: flex;
  margin-left: 2em;
}

.upload-button {
  position: relative;
  background-color: var(--font-color-footer);
  margin-right: 2em;
}

.upload-file-input {
  display: none;
}

.btn-spacing-default.btn-size-medium, .btn-spacing-default.btn-size-small {
  padding-block: 0em;
  padding-inline: 1.5em;
  display: flex;
  padding: 1em;
  background-color: var(--color-surface-subdued);
  border-radius: 1em;
}

.upload-btn.upload-icon > svg{
  color: var(--font-color-base);
}

.upload-icon~.upload-text, .upload-text~.upload-icon {
  margin-inline-start: var(--size-8);
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.create-button {
  position: relative;
  background-color: var(--font-color-link);
  margin-right: 2em;
}

.create-button.btn-spacing-default.btn-size-medium, .create-button.btn-spacing-default.btn-size-small {
  padding-block: 0em;
  padding-inline: 1.5em;
  display: flex;
  padding: 1em;
  background-color: var(--primary-default);
  border-radius: 1em;
}

.create-icon-wrapper {
  color: var(--white);
}

.text-wrapper {
  margin-inline-start: 0.8em;
  color: var(--white);
}
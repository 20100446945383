/**css variable names */ 

:root {
  --white: #fff;
  --black: #000;
  --font-color-base: #666;
  --font-color-dark: #212529;
  --font-color-light: #b0aebe;
  --font-color-link: #57a6ff;
  --primary-color: #3498db;
  --footer: #34314b;
  --footer-base: #2e2b45;
  --contrast: rgba(246, 248, 249, 0.8);
  --success: #64ecf5;
  --danger: #ff8b8d;
  --grad-1: #a1ecff;
  --grad-2: #7dbcff;
  --color-surface-subdued: rgba(50,52,54,.06);
  --primary-default: #2b4c90;

  --size-8: 0.8em;
  --size-10: 1em;
  --size-14: 1.4em;
  --size-16: 1.6em;
  --size-18: 1.8em;
  --size-20: 2em;
  --size-30: 3em;
  --size-40: 4em;
  --size-50: 5em;
  --size-100: 10em;
  --size-150: 15em;
  --size-200: 20em;
}

html { 
  scroll-behavior: smooth; 
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 62.5%;
  line-height: 1.6;
  letter-spacing: 0;
  color: var(--font-color-base);
  margin: 0;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a { text-decoration: none; }
p { margin-top: 0; }

h1 {
  font-size: 5.2em;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 2.8em;
  font-weight: 500;
  line-height: 1.2;
}

img {
  vertical-align: middle;
  border-style: none;
}

.container {
  width: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: var(--font-color-dark);
  text-align: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  border-radius: 2.0em;
  transition: ease-in-out 0.3s;
}

.btn-primary {
  background-image: linear-gradient(90deg, var(--grad-1) 0%, var(--grad-2) 100%);
  border: 0.1em solid var(--grad-1);
  box-shadow: 0 1em 2em rgba(161, 236, 255, 0.5);
  transition: ease-in-out 0.3s;
  color: var(--white);
  padding: 0.7em 2.6em;
}

.btn-white {
  background-color: var(--white);
  color: var(--font-color-link);
  box-shadow: 0em 1em 2em rgba(255, 255, 255, 0.35);
  padding: 0.7em 2.8em;
}
 

/* comingSoon.css */

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.coming-soon-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.82); /* Adjust the last value for transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.coming-soon-content {
    text-align: center;
    color: white;
    font-size: 30px;
}


.coming-soon-h1 {
    text-align: center;
    color: white;
    font-size: 64px;
    font-weight: bold;
}